import {
  computed, readonly, ref, useContext, useRoute, reactive, watch, onMounted
} from '@nuxtjs/composition-api';
import {addItemCommand, addItemsCommand} from '~/modules/checkout/composables/useCart/commands/addItemCommand';
import {applyCouponCommand} from '~/modules/checkout/composables/useCart/commands/applyCouponCommand';
import {loadCartCommand} from '~/modules/checkout/composables/useCart/commands/loadCartCommand';
import {loadTotalQtyCommand} from '~/modules/checkout/composables/useCart/commands/loadTotalQtyCommand';
import {removeCouponCommand} from '~/modules/checkout/composables/useCart/commands/removeCouponCommand';
import {removeItemCommand} from '~/modules/checkout/composables/useCart/commands/removeItemCommand';
import {updateItemQtyCommand} from '~/modules/checkout/composables/useCart/commands/updateItemQtyCommand';
import {Logger} from '~/helpers/logger';
import {Cart, CartItemInterface, ProductInterface} from '~/modules/GraphQL/types';
import {useCartStore} from '~/modules/checkout/stores/cart';
import {useWishlist} from '~/modules/wishlist/composables/useWishlist';
import {Product} from '~/modules/catalog/product/types';
import {ComposableFunctionArgs} from '~/composables';
import {UseCartErrors, UseCartInterface} from './useCart';
import {useAddToCartPopup, useGtm, useNotifications, useUrl} from "~/composables/Palmers";
import {findItemsOnCart} from "~/modules/checkout/helpers/findItemOnCart";

/**
 * Allows loading and manipulating cart of the current user.
 *
 * See the {@link UseCartInterface} for a list of methods and values available in this composable.
 */


const state = reactive({
  loading: false,
  itemInProgress: {},
  loadingFor: ''
});

export function useCart<CART extends Cart, CART_ITEM extends CartItemInterface, PRODUCT extends ProductInterface>(): UseCartInterface<CART,
  CART_ITEM,
  PRODUCT> {
  const loading = computed(() => state.loading);
  const itemInProgress = computed(() => state.itemInProgress);
  const error = ref<UseCartErrors>({
    addItem: null,
    removeItem: null,
    updateItemQty: null,
    load: null,
    clear: null,
    applyCoupon: null,
    removeCoupon: null,
    loadTotalQty: null,
  });
  const {app} = useContext();
  const context = app.$vsf;
  const route = useRoute();
  const cartStore = useCartStore();
  const cart = computed(() => cartStore.cart as CART);
  const apiState = context.$magento.config.state;
  const {loading: wishlistLoading, afterAddingWishlistItemToCart} = useWishlist();
  const {getPageUrl} = useUrl();
  const {trackRemoveFromCart, trackAddToCart} = useGtm();

  const {sendMessage} = useNotifications();

  const handleCartErrors = async () => {
    const currentRouteName = route.value?.name;

    if (cart.value?.['errors']?.[0]?.['message'] && currentRouteName.indexOf('cart') <= -1 && route.value.matched?.[0]?.name.indexOf('checkout') > -1 && cart.value?.items?.length > 0) {
      sendMessage('Cart load failed', cart.value?.['errors']?.[0]?.['message']);
      await app.router.push(getPageUrl({name: 'cart'}));
    }
  }

  onMounted(async () => {
    await handleCartErrors();
  })

  watch(route, async () => {
    await handleCartErrors();
  })
  watch(error, async () => {
    await handleCartErrors();
  })
  watch(cart, async () => {
    await handleCartErrors();
  })
  /**
   * Assign new cart object
   * @param newCart
   *
   * @return void
   */
  const setCart = (newCart: CART): void => {
    Logger.debug('useCart.setCart', newCart);

    cartStore.$patch((state) => {
      state.cart = {
        ...state.cart,
        ...newCart
      };
    });
  };

  /**
   * Check if product is in the cart
   * @param product
   *
   * @return boolean
   */
  const isInCart = (product: PRODUCT): boolean => !!cart.value?.items?.find((cartItem) => cartItem?.product?.uid === product.uid);

  const load = async ({customQuery = {}, customHeaders = {}, realCart = false} = {customQuery: {cart: 'cart'}, customHeaders: {}}): Promise<void> => {
    Logger.debug('useCart.load');

    try {

      if(state.loadingFor === 'cart') return ;

      state.loadingFor = 'cart';
      state.loading = true;

      let loadedCart,loadedShippingBar;

      if(context.$magento.config.state.getCartId()) {
        [loadedCart, loadedShippingBar] = await Promise.all([
          loadCartCommand.execute(context, {customQuery, customHeaders, realCart}),
          //@ts-ignore
          context.$magento.api.getFreeShippingBar()
        ]);
      }else {
        loadedCart = await loadCartCommand.execute(context, {customQuery, customHeaders, realCart});
        if(loadedCart?.id) {
          //@ts-ignore
          loadedShippingBar = await context.$magento.api.getFreeShippingBar(loadedCart.id);
        }
      }

      if (loadedShippingBar?.data?.cart?.['free_shipping_bar']) {
        loadedCart['free_shipping_bar'] = loadedShippingBar.data.cart['free_shipping_bar'];
      }

      cartStore.$patch((state) => {
        state.cart = loadedCart;
      });
      error.value.load = null;
    } catch (err) {
      error.value.load = err;
      Logger.error('useCart/load', err);
    } finally {
      state.loading = false;
      state.loadingFor = '';
    }
  };

  const clear = async ({customQuery, customHeaders} = {customQuery: {cart: 'cart'}, customHeaders: {}}): Promise<void> => {
    Logger.debug('useCart.clear');

    try {
      state.loadingFor = 'clear';
      state.loading = true;
      context.$magento.config.state.removeCartId();
      const loadedCart = await loadCartCommand.execute(context, {customQuery, customHeaders});

      cartStore.$patch((state) => {
        state.cart = loadedCart;
      });
    } catch (err) {
      error.value.clear = err;
      Logger.error('useCart/clear', err);
    } finally {
      state.loading = false;
      state.loadingFor = '';
    }
  };

  const loadTotalQty = async (params?: ComposableFunctionArgs<{}>): Promise<void> => {
    Logger.debug('useCart.loadTotalQty');

    try {
      state.loadingFor = 'qty';
      state.loading = true;

      if (!cart.value?.id) {
        const totalQuantity = await loadTotalQtyCommand.execute(context, params);

        if (!apiState.getCartId()) {
          await load({realCart: true});
        }

        cartStore.$patch((state) => {
          state.cart = {
            ...state.cart,
            total_quantity: totalQuantity
          }
        });
      }
    } catch (err) {
      error.value.loadTotalQty = err;
      Logger.error('useCart/loadTotalQty', err);
    } finally {
      state.loading = false;
      state.loadingFor = '';
    }
  };

  const addItems = async ({products, customQuery, customHeaders}): Promise<void> => {
    Logger.debug('useCart.addItems', 'mass');

    if (!customQuery) {
      customQuery = {addProductsToCart: 'addProductsToCart'}
    }

    try {
      state.loadingFor = 'addItems';
      state.loading = true;

      if (!apiState.getCartId()) {
        await load({realCart: true});
      }

      const updatedCart = await addItemsCommand.execute(context, {
        products,
        customQuery,
        customHeaders,
      });

      trackAddToCart(products);

      error.value.addItem = null;
      cartStore.$patch((state) => {
        state.cart = {
          ...state.cart,
          ...updatedCart
        };
      });
    } catch (err) {
      sendMessage('Cannot add to cart', err);
      error.value.addItem = err;
      Logger.error('useCart/addItem', err);
    } finally {
      state.loading = false;
      state.loadingFor = '';
    }
  };

  const addItem = async ({
                           product, quantity, productConfiguration, customQuery, customHeaders,
                         }, deactivateModal = false): Promise<void> => {
    Logger.debug('useCart.addItem', {product, quantity});

    if (!customQuery) {
      customQuery = {addProductsToCart: 'addProductsToCart'}
    }

    try {
      state.loadingFor = 'addToCart';
      state.loading = true;
      state.itemInProgress = product;

      if (!apiState.getCartId()) {
        await load({realCart: true});
      }

      const updatedCart = await addItemCommand.execute(context, {
        currentCart: cart.value,
        product,
        quantity,
        productConfiguration,
        customQuery,
        customHeaders,
      });

      trackAddToCart([{
        product: product,
        quantity: quantity,
        productConfiguration: productConfiguration
      }]);

      if (!deactivateModal) {
        const {activateModal} = useAddToCartPopup();

        activateModal(product, productConfiguration);
      }

      error.value.addItem = null;
      cartStore.$patch((state) => {
        state.cart = {
          ...state.cart,
          ...updatedCart
        };
      });
    } catch (err) {
      sendMessage('Cannot add to cart', err);
      error.value.addItem = err;
      Logger.error('useCart/addItem', err);
    } finally {
      if (!wishlistLoading.value && route.value.query?.wishlist) {
        afterAddingWishlistItemToCart({
          product,
          cartError: error.value.addItem,
        });
      }
      state.loading = false;
      state.loadingFor = '';
    }
  };

  const removeItem = async ({product}) => {
    Logger.debug('useCart.removeItem', {product});

    try {
      state.loadingFor = 'removeItem';
      state.loading = true;
      state.itemInProgress = product;

      const updatedCart = await removeItemCommand.execute(context, {
        currentCart: cart.value,
        product,
        customQuery: {removeItemFromCart: 'removeItemFromCart'},
        customHeaders: {},
      });

      const item = cart.value.items.find((cartItem) => cartItem?.uid === product?.uid);

      trackRemoveFromCart([item])

      error.value.removeItem = null;
      cartStore.$patch((state) => {
        state.cart = {
          ...state.cart,
          ...updatedCart
        };
      });
    } catch (err) {
      state.loadingFor = 'removeItem';
      error.value.removeItem = err;
      Logger.error('useCart/removeItem', err);
    } finally {
      state.loading = false;
    }
  };

  const updateItemQty = async ({product, quantity, customQuery = {updateCartItems: 'updateCartItems'}}): Promise<void> => {
    Logger.debug('useCart.updateItemQty', {
      product,
      quantity,
    });

    if (quantity && quantity > 0) {
      try {
        state.loadingFor = 'updateItemQty';
        state.loading = true;
        const updatedCart = await updateItemQtyCommand.execute(context, {
          currentCart: cart.value,
          product,
          quantity,
          customQuery,
        });

        const item = cart.value.items.find((cartItem) => cartItem?.uid === product?.uid);

        if(quantity > item.quantity) {
          trackAddToCart([{
            ...item,
            quantity: quantity - item.quantity
          }])
        }else {
          trackRemoveFromCart([{
            ...item,
            quantity: item.quantity - quantity
          }])
        }

        error.value.updateItemQty = null;
        cartStore.$patch((state) => {
          state.cart = {
            ...state.cart,
            ...updatedCart
          };
        });
      } catch (err) {
        sendMessage('Update items error', err);
        error.value.updateItemQty = err;
        Logger.error('useCart/updateItemQty', err);
      } finally {
        state.loading = false;
        state.loadingFor = '';
      }
    }
  };

  const handleCoupon = async (couponCode = null, customQuery = null): Promise<{ updatedCart: object, errors: object }> => {
    const variables = {
      currentCart: cart.value,
      customQuery,
      couponCode,
    };

    const {updatedCart, errors} = couponCode
      ? await applyCouponCommand.execute(context, variables)
      : await removeCouponCommand.execute(context, variables);

    if (errors) {
      throw errors[0];
    }

    if (updatedCart) {
      cartStore.$patch((state) => {
        state.cart = {
          ...state.cart,
          ...updatedCart,
          shipping_addresses: cartStore.cart?.shipping_addresses,
          billing_address: cartStore.cart?.billing_address,
          selected_payment_method: cartStore.cart?.selected_payment_method,
        };
      });
    }

    return {updatedCart, errors};
  };

  const applyCoupon = async ({couponCode, customQuery}): Promise<void> => {
    Logger.debug('useCart.applyCoupon');

    try {
      state.loadingFor = 'applyCoupon';
      state.loading = true;
      const {updatedCart, errors} = await handleCoupon(couponCode, customQuery);

      const thisCoupon = updatedCart?.['applied_coupons']?.filter((coupon) => coupon.code.toLowerCase() === couponCode.toLowerCase());

      if (!thisCoupon?.length && couponCode || errors) {
        error.value.applyCoupon = {
          name: 'Coupon error',
          message: 'Der Gutscheincode ist nicht gültig. Überprüfen Sie den Code und versuchen Sie es erneut.'
        };

        sendMessage(error.value.applyCoupon.name, error.value.applyCoupon.message)

        Logger.error('useCart/applyCoupon', error.value.applyCoupon);
      } else {
        error.value.applyCoupon = null;
      }
    } catch (err) {
      error.value.applyCoupon = err;
      Logger.error('useCart/applyCoupon', err);
    } finally {
      state.loading = false;
      state.loadingFor = '';
    }
  };

  const removeCoupon = async ({couponCode = null, customQuery}): Promise<void> => {
    Logger.debug('useCart.removeCoupon');

    try {
      state.loadingFor = 'removeCoupon';
      state.loading = true;
      await handleCoupon(couponCode, customQuery);
      error.value.applyCoupon = null;
    } catch (err) {
      error.value.removeCoupon = err;
      Logger.error('useCart/removeCoupon', err);
    } finally {
      state.loading = false;
      state.loadingFor = '';
    }
  };

  const canAddToCart = (product: Product, qty = 1) => {
    let productToCheck = product;

    if(product?.[0]?.sku) {
      productToCheck = product[0];
    }

    // eslint-disable-next-line no-underscore-dangle
    // if (product?.__typename === 'ConfigurableProduct') {
    //   return !!product?.configurable_product_options_selection?.variant
    //     ?.uid;
    // }
    const inStock = !productToCheck?.stock_status || productToCheck?.stock_status !== 'OUT_OF_STOCK';
    const stockLeft = productToCheck?.only_x_left_in_stock === null
      ? true
      : qty <= productToCheck?.only_x_left_in_stock;
    return inStock && stockLeft;
  };

  const updateItem = async ({product, productConfiguration, customHeaders}): Promise<void> => {
    const updateData = async (items, cartItemUid) => {
      const {data} = await app.context.$vsf.$magento.api.updateProductInCart(
        {
          cartId: cart.value.id,
          cartItemUid: cartItemUid,
          items: items
        },
        customHeaders
      );
      const updatedCart = data['addProductsToCart']?.cart || {};

      if (Object.keys(updatedCart).length) {
        cartStore.$patch((state) => {
          state.cart = {
            ...state.cart,
            ...updatedCart
          };
        });
      }
    }

    Logger.debug('useCart.updateItem', {
      product,
      productConfiguration,
    });

    try {
      state.loadingFor = 'updateItem';
      state.loading = true;
      state.itemInProgress = product;
      const itemsInCart = findItemsOnCart(cart.value, product);

      let itemInCart = itemsInCart[0];

      if (itemsInCart.length > 1) {
        const itemToRemove = itemsInCart.find((item) => item['configured_variant'].sku === product[0].sku);

        function findItemMatchingObjectValues(obj, arr) {
          const objectValues = Object.values(obj);

          return arr.find(item => {
            const optionValues = item.configurable_options.map(option => option.configurable_product_option_value_uid);
            return objectValues.every(value => optionValues.includes(value));
          });
        }

        const itemToUpdate = findItemMatchingObjectValues(productConfiguration, itemsInCart);

        if (itemToUpdate) {
          itemInCart.quantity = itemToRemove.quantity + itemToUpdate.quantity;
          await Promise.all([
            removeItem({product: itemToRemove}),
            updateItemQty({product: itemToUpdate, quantity: itemInCart.quantity})
          ])
          return;
        }

        itemInCart = itemToRemove;
      }

      const cartItems = [];

      switch (product.__typename) {
        case 'ConfigurableProduct': {
          const selectedOptions = Object.values(productConfiguration as object);

          cartItems.push({
            quantity: itemInCart.quantity,
            selected_options: selectedOptions,
            sku: itemInCart.product.sku
          })

          break;
        }
        case 'BundleProduct': {
          const createEnteredOptions = () =>
            // eslint-disable-next-line implicit-arrow-linebreak
            product.bundle_options.map((bundleOption) => ({
              ...bundleOption,
              value: bundleOption.value.toString(),
            }));

          cartItems.push({
            quantity: itemInCart.quantity,
            entered_options: createEnteredOptions(),
            sku: itemInCart.product.sku
          })

          break;
        }
        case 'GroupedProduct': {
          cartItems.push(product.items.map((item) => ({
            quantity: itemInCart.quantity,
            sku: itemInCart.product.sku
          })))

          break
        }
        default: {
          cartItems.push({
            quantity: itemInCart.quantity,
            sku: itemInCart.product.sku
          })

          break;
        }
      }

      await updateData(cartItems, itemInCart.uid);
      error.value = null;
    } catch (err) {
      error.value = err;
      Logger.error('useCart/updateItem', err);
    } finally {
      state.loading = false;
      state.loadingFor = '';
    }
  };

  return {
    setCart,
    cart,
    loadTotalQty,
    isInCart,
    addItem,
    addItems,
    load,
    removeItem,
    clear,
    updateItemQty,
    applyCoupon,
    removeCoupon,
    canAddToCart,
    updateItem,
    loading: readonly(loading),
    error: readonly(error),
    itemInProgress
  };
}

export default useCart;
export * from './useCart';
