var Form = function(url, fields) {
  var renderDataAsHiddenFields = function(data) {
    var output = '';
    Object.keys(data).forEach(function(key) {
      var value = data[key];
      if (Array.isArray(value)) {
        value.forEach(function(item) {
          output += renderHiddenField(key + '[]', item);
        });
      } else {
        output += renderHiddenField(key, value);
      }
    });
    return output;
  };

  var renderHiddenField = function(key, value) {
    if (typeof value === 'string') {
      value = value.replace(/"/g, "&quot;");
    }
    return '<input type="hidden" name="' + key + '" value="' + value + '" />';
  };

  var createElement = function() {
    var formElement = document.createElement('form');
    formElement.action = url;
    formElement.method = 'POST';
    formElement.innerHTML = renderDataAsHiddenFields(fields);
    return formElement;
  };

  /**
   * Submit the data via POST to the url.
   *
   * @return void
   */
  this.submit = function() {
    var formElement = createElement();
    document.body.appendChild(formElement);
    formElement.submit();
  };

  this.getForm = function() {
    return createElement();
  };
};

/**
 * Form validation registry
 */
Form.Validation = (function() {
  var validators = {};

  /**
   * Register a js function postfix.
   *
   * @param string group
   * @param function js postfix
   */
  function register(group, postfix) {
    validators[group] = postfix;
  }

  /**
   * Run the registered validators in the given group.
   *
   * @param group
   * @param function callback
   */
  function validate(group, successCallback, failureCallback) {
    var form = document.querySelector('form[name="' + group + '"]');
    if (!form) {
      successCallback([]);
      return;
    }

    var formId = form.id;
    var postfix = validators[group];

    if (typeof postfix === 'undefined') {
      successCallback([]);
      return;
    }

    var validateFunctionName = 'cwValidateFields' + postfix;
    var validateFunction = window[validateFunctionName];

    if (typeof validateFunction !== 'undefined') {
      validateFunction(successCallback, failureCallback);
    } else {
      successCallback([]);
    }
  }

  return {
    register: register,
    validate: validate,
  };
})();

/**
 * Remove the form field name attributes of an entire form to prevent them from being sent to the server.
 *
 * @return void
 */
Form.removeFieldNames = function(formElement) {
  var submittableTypes = ['select', 'input', 'button', 'textarea'];
  submittableTypes.forEach(function(type) {
    var elements = formElement.querySelectorAll(type + '[name]');
    Array.prototype.forEach.call(elements, function(element) {
      Form.removeFieldName(element);
    });
  });
};

/**
 * Remove the form field name attribute of a single element to prevent it from being sent to the server.
 *
 * @return void
 */
Form.removeFieldName = function(element) {
  element.setAttribute('data-field-name', element.getAttribute('name'));
  element.removeAttribute('name');
};

/**
 * Get the values of a form.
 *
 * @param object formElement
 * @param boolean dataProtected
 * @return object
 */
Form.getValues = function(formElement, dataProtected) {
  var output = {};
  var nameAttribute = dataProtected ? 'data-field-name' : 'name';
  var elements = formElement.querySelectorAll('*[' + nameAttribute + ']');
  Array.prototype.forEach.call(elements, function(element) {
    var name = element.getAttribute(nameAttribute);
    if (name) {
      if (element.type === 'radio') {
        if (element.checked) {
          output[name] = element.value;
        }
      } else {
        output[name] = element.value;
      }
    }
  });
  return output;
};

/**
 * Validate the form fields.
 *
 * @return boolean
 */
Form.validate = function(name, successCallback, failureCallback) {
  var self = this;
  return Form.Validation.validate(
    name,
    function(valid) {
      valid.forEach(function(elementId) {
        var element = document.getElementById(elementId);
        if (element) {
          element.classList.remove('mage-error');
          var errorElement = document.getElementById(elementId + '-error');
          if (errorElement) {
            errorElement.remove();
          }
        }
      });
      successCallback();
    },
    function(errors, valid) {
      valid.forEach(function(elementId) {
        var element = document.getElementById(elementId);
        if (element) {
          element.classList.remove('mage-error');
          var errorElement = document.getElementById(elementId + '-error');
          if (errorElement) {
            errorElement.remove();
          }
        }
      });
      Object.keys(errors).forEach(function(elementId) {
        var error = errors[elementId];
        var element = document.getElementById(elementId);
        if (element) {
          var fieldElement = element.closest('.field');
          if (fieldElement) {
            var errorElement = document.createElement('div');
            errorElement.id = elementId + '-error';
            errorElement.innerHTML = self.fieldErrorTmpl({
              id: elementId,
              message: error,
            });
            fieldElement.appendChild(errorElement);
          }
        }
      });
      failureCallback();
    }
  );
};

export default Form
