import {computed, reactive, readonly, ref, useContext} from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import type { GetProductSearchParams } from '~/modules/catalog/product/types';
import {sortingOptions, SortingOptionsValuesEnum} from '~/modules/catalog/category/composables/useFacet/sortingOptions';
import { perPageOptions } from '~/modules/catalog/category/composables/useFacet/perPageOptions';
import { createProductAttributeFilterInput } from '~/modules/catalog/category/composables/useFacet/input/createProductAttributeFilterInput';
import { createProductAttributeSortInput } from '~/modules/catalog/category/composables/useFacet/input/createProductAttributeSortInput';
import type {
  UseFacetInterface, UseFacetErrors, UseFacetSearchResult, FacetSearchParams,
} from './useFacet';

/**
 * Allows searching for products with pagination, totals and sorting options.
 *
 * See the {@link UseFacetInterface} for a list of methods and values available in this composable.
 */
const state = reactive({
  loading: false,
  items: {}
});

export function useFacet(): UseFacetInterface {
  const loading = computed(() => state.loading);
  const items = computed(() => state.items);
  const {$vsf} = useContext();

  const result = ref<UseFacetSearchResult>({ data: null, input: null });
  const error = ref<UseFacetErrors>({
    search: null,
  });
  const defaultItemsPerPage = 20;
  const search = async (params?: FacetSearchParams) => {
    Logger.debug('useFacet/search', params);

    result.value.input = params;
    try {
      state.loading = true;

      const pageSize = params.itemsPerPage ? params.itemsPerPage : defaultItemsPerPage;

      const productSearchParams: GetProductSearchParams = {
        pageSize,
        search: params.term ? params.term : '',
        filter: createProductAttributeFilterInput(params),
        sort: createProductAttributeSortInput(params.sort || SortingOptionsValuesEnum.DEFAULT),
        currentPage: params.page,
        //@ts-ignore
        updated: params.updated ? params.updated : '',
      };

      function mergeObjects(obj1, obj2, obj3) {
        if ((obj1.items.length !== obj2.items.length) !== (obj1.items.length !== obj3.items.length)) {
          return obj1;
        }

        return {
          ...obj1,
          items: obj1.items.map((item, index) => ({
            ...item,
            ...obj2.items[index],
            ...obj3.items[index]
          }))
        };
      }

      if(params.sort === 'search') {
        delete productSearchParams['sort'];
      }

      const [dataProduct, dataProductReviews, dataProductLabel] = await Promise.all([
        //@ts-ignore
        <Promise>$vsf.$magento.api.getFacetDataQuery(productSearchParams),
        //@ts-ignore
        <Promise>$vsf.$magento.api.getFacetDataReviews(productSearchParams),
        //@ts-ignore
        <Promise>$vsf.$magento.api.getFacetDataLabels(productSearchParams),
      ])

      const {data: productData} = dataProduct;
      const {data: reviewsData} = dataProductReviews;
      const {data: labelsData} = dataProductLabel;

      const products = (productData?.products?.items && reviewsData?.products?.items && labelsData?.products?.items) ? mergeObjects(productData.products, reviewsData.products, labelsData.products) : productData?.products || null;
      Logger.debug('[Result]:', { products });

      state.items = products?.items;

      result.value.data = {
        items: products?.items || [],
        total: products?.total_count,
        availableSortingOptions: sortingOptions,
        perPageOptions,
        itemsPerPage: pageSize,
      };
      error.value.search = null;
    } catch (err) {
      error.value.search = err;
      Logger.error('useFacet/search', err);
    } finally {
      state.loading = false;
    }
  };

  return {
    search,
    result,
    error: readonly(error),
    loading: readonly(loading),
    items
  };
}

export * from './useFacet';
export default useFacet;
