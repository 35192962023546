import type { Plugin } from '@nuxt/types';
import type { ApolloQueryResult } from '@apollo/client/core/types';

export const hasGraphqlCartError = (res: ApolloQueryResult<unknown>) => res?.errors
  ?.some((error) => error?.message && (error?.message.indexOf('cart with ID') > -1 || error?.message.indexOf('The cart') > -1)) || false;

const plugin : Plugin = ({ app }) => {
  const interceptor = app.$vsf.$magento.client.interceptors.response.use((res: any) => {
    if(process.server) {
      app.$vsf.$magento.client.interceptors.response.eject(interceptor);
    }

    if (!hasGraphqlCartError(res.data as ApolloQueryResult<unknown>)) {
      return res;
    }
    app.$vsf.$magento.config.state.removeCartId();
    // @ts-ignore
    app.$vsf.$magento.config.state.removeSessionId();

    return false;
  }, function (error) {
    if(process.server) {
      app.$vsf.$magento.client.interceptors.response.eject(interceptor);
    }

    console.error(error);

    return;
  });
};

export default plugin;
