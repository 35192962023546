import {readonly, ref, useContext} from '@nuxtjs/composition-api';
import {Logger} from '~/helpers/logger';
import useCart from '~/modules/checkout/composables/useCart';
import type {ShippingCartAddress} from '~/modules/GraphQL/types';
import type {UseShippingErrors, UseShippingInterface, UseShippingLoadParams, UseShippingSaveParams,} from './useShipping';
import {useNotifications} from "~/composables/Palmers";

/**
 * Allows loading the shipping information for
 * the current cart and saving (selecting) other shipping information for the
 * same cart.
 *
 * See the {@link UseShippingInterface} for a list of methods and values available in this composable.
 */
export function useShipping(): UseShippingInterface {
  const loading = ref(false);
  const error = ref<UseShippingErrors>({load: null, save: null});
  const {cart, load: loadCart, setCart} = useCart();
  const {app, $vsf} = useContext();
  const {getAllErrors, sendMessage} = useNotifications();

  const load = async (params: UseShippingLoadParams = {customQuery: {cart: 'cart'}}): Promise<ShippingCartAddress | null> => {
    Logger.debug('useShipping.load');
    let shippingInfo: ShippingCartAddress | null = null;

    try {
      loading.value = true;
      await loadCart(params);
      [shippingInfo] = cart.value.shipping_addresses;
      error.value.load = null;
    } catch (err) {
      error.value.load = err;
      Logger.error('useShipping/load', err);
    } finally {
      loading.value = false;
    }
    return shippingInfo;
  };

  const save = async ({shippingDetails}: UseShippingSaveParams): Promise<ShippingCartAddress | null> => {
    Logger.debug('useShipping.save');
    let shippingInfo: ShippingCartAddress | null = null;

    try {
      loading.value = true;

      const {id} = cart.value;

      let cartId = id;

      if(!cartId && $vsf.$magento.config.state.getCartId()) {
        cartId = $vsf.$magento.config.state.getCartId();
      }

      const {
        apartment,
        neighborhood,
        extra,
        customerAddressId,
        ...address
      } = shippingDetails;

      const shippingData = customerAddressId
        ? {customer_address_id: customerAddressId}
        : {
          address: {
            ...address,
            street: [address.street, apartment, neighborhood, extra].filter(Boolean),
          },
        };

      const shippingAddressInput = {
        cart_id: cartId,
        shipping_addresses: [
          {
            ...shippingData,
          },
        ],
      };

      const {data, errors} = await app.$vsf.$magento.api.setShippingAddressesOnCart(shippingAddressInput, {setShippingAddressesOnCart: 'setShippingAddressesOnCart'});

      if(errors) {
        // @ts-ignore
        error.value.save = getAllErrors(errors);
        Logger.error('useShipping/save', error.value.save);
        sendMessage('Shipping save error', error.value.save)
      }else {
        Logger.debug('[Result]:', {data});
        [shippingInfo] = data.setShippingAddressesOnCart.cart.shipping_addresses;

        setCart({
          ...cart.value,
          email: data.setShippingAddressesOnCart.cart?.['email'] || '',
          shipping_addresses: data.setShippingAddressesOnCart.cart.shipping_addresses,
          prices: data.setShippingAddressesOnCart.cart?.['prices']
        })

        error.value.save = null;
      }
    } catch (err) {
      error.value.save = err;
      Logger.error('useShipping/save', err);
    } finally {
      loading.value = false;
    }

    return shippingInfo;
  };

  return {
    load,
    save,
    error: readonly(error),
    loading: readonly(loading),
  };
}

export * from './useShipping';
export default useShipping;
