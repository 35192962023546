import { ComposableFunctionArgs } from '~/composables/types';
import { AvailableShippingMethod } from '~/modules/GraphQL/types';

export const getGuestShippingMethodsCommand = {
  execute: async (context, params: ComposableFunctionArgs<{ cartId: string }>): Promise<AvailableShippingMethod[]> => {
    const { data } = await context.$magento.api.getAvailableShippingMethods(
      { cart_id: params.cartId },
      {getAvailableShippingMethods: 'getAvailableShippingMethods'},
      params?.customHeaders,
    );
    const hasAddresses = data?.cart?.shipping_addresses && data?.cart?.shipping_addresses?.length > 0;

    return hasAddresses ? data?.cart?.shipping_addresses[0]?.available_shipping_methods : [];
  },
};
