export default async (context) => {
  const {$vsf} = context;

  const apiState = $vsf.$magento.config.state;

  const createRealCart = async () => {
    const { data } = await $vsf.$magento.api.createEmptyCart();
    if(data?.createEmptyCart) {
      apiState.setCartId(data.createEmptyCart);
    }
  };

  const cartId = apiState.getCartId();

  if (!cartId && !apiState.getCustomerToken()) {
    await createRealCart();
  }
}
