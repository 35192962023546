import {Middleware} from '@nuxt/types';
const CryptoJS = require('crypto-js');

const middleware: Middleware = (context) => {
  const login = context.localeRoute({name: 'login'});
  const token = context.route?.params?.token;

  if(context.route?.query?.redirected) return;

  if(!token || token === 'sso-login') {
    context.redirect(login);
  }

  const encrypted = CryptoJS.AES.encrypt(token, process.env.VSF_TOKENS_KEY);

  const tokenCrypted = encrypted.toString().replaceAll('+','xMl3Jk').replaceAll('/','Por21Ld').replaceAll('=','Ml32');

  const SsoLogin = context.localeRoute({name: 'sso-login', params: {token: tokenCrypted}, query: {redirected: 'true'}});

  context.redirect(SsoLogin);
};
export default middleware;
