import { defineStore } from 'pinia';

interface AttributesState {
  attributesData: any;
}

export const useAttributesStore = defineStore('attributes', {
  state: (): AttributesState => ({
    attributesData: null,
  }),
  actions: {
    async load() {
      try {
        //@ts-ignore
        const {data} = await this.$nuxt.app.$vsf.$magento.api.getProductAttributeList();

        let attributesData = data?.['productAttributeList'] || null;

        if(attributesData) {
          const attributes = [];

          for(let a = 0; a < attributesData.length; a++) {
            attributes.push(attributesData[a]?.attribute_code);
          }

          attributesData = {
            attributes: attributes,
            items: attributesData
          }
        }

        this.attributesData = attributesData;
      } catch (error) {
        console.error("Error loading attributes:", error);
      }
    },
  },
});
