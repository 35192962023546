import {CustomerAddressInput} from '~/modules/GraphQL/types';

export const transformUserCreateAddressInput = (addressInputParams): CustomerAddressInput => {
  if(addressInputParams?.address?.['isTrusted']) {
    delete addressInputParams.address['isTrusted'];
  }

  const {
    apartment,
    neighborhood,
    extra,
    ...address
  } = addressInputParams.address;

  const {
    region
  } = address;

  const street = [address.street];

  if (apartment) street.push(apartment);

  if (neighborhood) street.push(neighborhood);

  if (extra) street.push(extra);

  const regionObjs = typeof region !== 'string' ? {region: region.region, region_id: region.region_id, region_code: region.region_code} : {
    region_code: '',
    region_id: null,
    region: region || null
  }

  return {
    ...address,
    region: regionObjs,
    street: street.filter(Boolean),
  }
};

export const transformUserUpdateAddressInput = (addressInputParams): {
  id: number;
  input: CustomerAddressInput;
} => {
  const {
    apartment,
    neighborhood,
    extra,
    id,
    // @ts-ignore
    // eslint-disable-next-line
    extension_attributes,
    // @ts-ignore
    // eslint-disable-next-line no-underscore-dangle
    __typename: typenameAddress,
    ...address
  } = addressInputParams.address;

  const {
    region
  } = address;

  const street = (Array.isArray(address.street) ? [...address.street] : [address.street]);

  if (apartment) street.push(apartment);

  if (neighborhood) street.push(neighborhood);

  if (extra) street.push(extra);

  const regionObjs = typeof region !== 'string' ? {region: region.region, region_id: region.region_id, region_code: region.region_code} : {
    region_code: '',
    region_id: null,
    region: region || null
  }

  const addressParams: CustomerAddressInput = {
    ...address,
    region: regionObjs,
    street: street.filter(Boolean),
  };

  return {
    id: id,
    input: {
      ...addressParams,
    },
  };
};

export const transformUserGetter = (addressInput) => ({
  ...addressInput,
  street: addressInput.street[0],
  apartment: addressInput.street[1],
  neighborhood: addressInput.street[2],
  extra: addressInput.street[3],
  region: addressInput.region.region
});
