export default async (context) => {
  const {app, route} = context;
  const COOKIE_NAME = 'awc';
  const CLICK_SOURCE_COOKIE_NAME = 'adv_awc';

  if(route.query?.[COOKIE_NAME]) {
    app.$cookies.set(CLICK_SOURCE_COOKIE_NAME, route.query[COOKIE_NAME], {
      path: '/',
      maxAge: 3600 * 24 * 365
    })
  }
}
