import {joinURL} from 'ufo';
import {createOperationsGenerator} from "@nuxt/image/dist/runtime";

const operationsGenerator = createOperationsGenerator({
  keyMap: {
    width: 'width',
    height: 'height',
    format: 'format',
  },
  joinWith: "&",
  formatter: (key, value) => `${key}=${value}`
})

const defaultModifiers = {
  format: 'auto'
}

export function getImage(src, {modifiers, baseURL} = {}) {
  const mergeModifiers = {...defaultModifiers, ...modifiers};
  const operations = operationsGenerator(mergeModifiers);

  return {
    url: joinURL(baseURL, src)  + (operations ? "?" + operations : "")
  }
}
