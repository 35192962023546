import type {Ref} from '@nuxtjs/composition-api';
import {ref, readonly, useContext} from '@nuxtjs/composition-api';
import {Logger} from '~/helpers/logger';
import type {
  UseUpsellProductsError,
  UseUpsellProductsInterface,
  UseUpsellProductsSearchParams,
} from './useUpsellProducts';

/**
 * Allows loading upsell products.
 *
 * See the {@link UseUpsellProductsInterface} for a list of methods and values available in this composable.
 */
export function useUpsellProducts(): UseUpsellProductsInterface {
  const {app} = useContext();
  const loading = ref(false);
  const error: Ref<UseUpsellProductsError> = ref({
    search: null,
  });

  const search = async (params: UseUpsellProductsSearchParams) => {
    const {
      customQuery,
      ...searchParams
    } = params;

    let results = null;

    try {
      loading.value = true;

      Logger.debug('[Magento] Load upsell products based on ', {searchParams});


      const [productRelatedData, relatedLabelsData, relatedReviewsData] = await Promise.all([
        app.$vsf.$magento.api.upsellProducts(searchParams, params?.customQuery || null, params?.customHeaders),
        //@ts-ignore
        app.$vsf.$magento.api.upsellProductsLabels(searchParams, params?.customQuery || null, params?.customHeaders),
        //@ts-ignore
        app.$vsf.$magento.api.upsellProductsReviews(searchParams, params?.customQuery || null, params?.customHeaders)
      ]);

      const {data} = productRelatedData;

      Logger.debug('[Result] Upsell products:', {data});

      const products = productRelatedData?.data?.['products'] || [];

      const productsLabels = relatedLabelsData?.data?.['products'] || [];
      const productsReviews = relatedReviewsData?.data?.['products'] || [];

      let beforeResult = [];

      for (let p = 0; p < products['items'].length; p++) {
        if (products['items'][p].upsell_products.length) {
          for (let r = 0; r < products['items'][p].upsell_products.length; r++) {
            if (beforeResult.filter((product) => product?.uid === products['items'][p].upsell_products[r].uid).length !== 0) continue;
            beforeResult.push({
              ...products['items'][p].upsell_products[r],
              ...productsLabels['items'][p].upsell_products[r],
              ...productsReviews['items'][p].upsell_products[r]
            });
          }
        }
      }

      results = beforeResult;

      error.value.search = null;
    } catch (err) {
      error.value.search = err;
      Logger.error('useUpsellProducts/search', err);
    } finally {
      loading.value = false;
    }

    return results;
  };

  return {
    search,
    loading: readonly(loading),
    error: readonly(error),
  };
}

export default useUpsellProducts;
export * from './useUpsellProducts';
