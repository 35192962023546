import {PalmersCustomerCreateInput} from "~/modules/customer/types/customer";

export const generateUserData = (userData: any): PalmersCustomerCreateInput => {
  const baseData = {
    email: userData.email,
    firstname: userData.firstName || userData.firstname,
    lastname: userData.lastName || userData.lastname,
    phone: userData.phone || userData.phone,
    sf_company: userData.sf_company || userData.sf_company,
    sf_agb: userData.sf_agb,
    sf_datenschutz: userData.sf_datenschutz,
    sf_is_online_customer: userData.sf_is_online_customer,
    sf_registration_type: userData.sf_registration_type,
    gender: userData.gender,
    prefix: userData.prefix,
    title_prefix: userData.title_prefix,
    suffix: userData.suffix,
    title_suffix: userData.title_suffix,
    date_of_birth: userData.date_of_birth,
    sf_street: userData.sf_street,
    sf_door: userData.sf_door,
    sf_house: userData.sf_house,
    sf_postcode: userData.sf_postcode,
    sf_city: userData.sf_city,
    sf_country_id: userData.sf_country_id,
    sf_telephone: userData.sf_telephone,
    sf_mobiltelefon: userData.sf_mobiltelefon,
    sf_nl_zustimmung: userData.sf_nl_zustimmung,
    sf_promotions: userData.sf_promotions,
    sf_anruf: userData.sf_anruf,
    sf_sms: userData.sf_sms,
    sf_geworben_von: userData.sf_geworben_von,
    sf_requestedcardtype: userData.sf_requestedcardtype,
    advertised_by: userData.advertised_by,
    sf_bh_cup: userData.sf_bh_cup,
    sf_hoeschen_groesse: userData.sf_hoeschen_groesse,
    sf_konfektionsgroesse: userData.sf_konfektionsgroesse,
    sf_schuhgroesse: userData.sf_schuhgroesse,
    sf_unterbrustweite: userData.sf_unterbrustweite,
  } as PalmersCustomerCreateInput;

  if (Object.prototype.hasOwnProperty.call(userData, 'is_subscribed')) {
    baseData.is_subscribed = userData.is_subscribed;
  }

  if (Object.prototype.hasOwnProperty.call(userData, 'dateOfBirth') || Object.prototype.hasOwnProperty.call(userData, 'date_of_birth')) {
    baseData.date_of_birth = userData.dateOfBirth || userData.date_of_birth;
  }

  if (Object.prototype.hasOwnProperty.call(userData, 'gender')) {
    baseData.gender = userData.gender;
  }

  if (Object.prototype.hasOwnProperty.call(userData, 'taxvat')) {
    baseData.taxvat = userData.taxvat;
  }

  if (Object.prototype.hasOwnProperty.call(userData, 'prefix')) {
    baseData.prefix = userData.prefix;
  }

  if (Object.prototype.hasOwnProperty.call(userData, 'title_prefix')) {
    baseData.title_prefix = userData.title_prefix;
  }

  if (Object.prototype.hasOwnProperty.call(userData, 'bra')) {
    baseData.bra = userData.bra;
  }

  if (Object.prototype.hasOwnProperty.call(userData, 'wear')) {
    baseData.wear = userData.wear;
  }

  if (Object.prototype.hasOwnProperty.call(userData, 'foot_wear')) {
    baseData.foot_wear = userData.foot_wear;
  }

  if (Object.prototype.hasOwnProperty.call(userData, 'suffix')) {
    baseData.suffix = userData.suffix;
  }

  if (Object.prototype.hasOwnProperty.call(userData, 'title_suffix')) {
    baseData.title_suffix = userData.title_suffix;
  }

  if (Object.prototype.hasOwnProperty.call(userData, 'password')) {
    baseData.password = userData.password;
  }

  if (Object.prototype.hasOwnProperty.call(userData, 'recaptchaToken')) {
    baseData.recaptchaToken = userData.recaptchaToken;
  }

  return baseData;
};
