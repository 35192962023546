import {Middleware} from '@nuxt/types';

const middleware: Middleware = (context) => {
  const {params} = context;

  if(!params.term || !params.searchResults) {
    const homePage = context.localeRoute({name: 'home'});
    context.redirect(homePage);
  }
};
export default middleware;
