import {computed, reactive, readonly, ref, useContext} from '@nuxtjs/composition-api';
import {findItemOnWishlist} from '~/modules/wishlist/helpers/findItemOnWishlist';
import {Logger} from '~/helpers/logger';
import {useWishlistStore} from '~/modules/wishlist/store/wishlistStore';
import type {Wishlist} from '~/modules/GraphQL/types';
import type {
  UseWishlistAddItemParams,
  UseWishlistErrors,
  UseWishlistInterface,
  UseWishlistIsInWishlistParams,
  UseWishlistLoadParams,
  UseWishlistRemoveItemParams,
  UseWishlistAfterAddingWishlistItemToCartParams,
} from '~/modules/wishlist/composables/useWishlist/useWishlist';
import {useUiNotification} from '~/composables';
import {useGtm, useGuestWishlist} from "~/composables/Palmers";

/**
 * Allows loading and manipulating wishlist of the current user.
 *
 * See the {@link UseWishlistInterface} for a list of methods and values available in this composable.
 */
const state = reactive({
  loading: false,
  itemInProgress: {}
});

export function useWishlist(): UseWishlistInterface {
  const {
    load: loadGuestWishlist,
    loadItemsCount: loadGuestItemsCount,
    addItem: guestAddItem,
    removeItem: guestRemoveItem,
    removeItems: guestRemoveItems,
    loading: guestLoading,
    itemInProgress: guestItemInProgress
  } = useGuestWishlist();

  const wishlistStore = useWishlistStore();
  const {app} = useContext();
  const {send: sendNotification} = useUiNotification();
  const loading = computed(() => state.loading);
  const itemInProgress = computed(() => state.itemInProgress);
  const calculateWishlistTotal = (wishlists: Wishlist[]) => wishlists.reduce((acc, current) => acc + (current?.items_count || 0), 0);
  const error = ref<UseWishlistErrors>({
    addItem: null,
    removeItem: null,
    load: null,
    clear: null,
    loadItemsCount: null,
    afterAddingWishlistItemToCart: null,
  });
  const apiState = app.$vsf.$magento.config.state;

  const {trackAddToWishlist, trackRemoveFromWishlist} = useGtm();

  const load = async (params?: UseWishlistLoadParams) => {
    if (!apiState.getCustomerToken()) return await loadGuestWishlist();

    Logger.debug('useWishlist/load');

    try {
      state.loading = true;
      Logger.debug('[Magento Storefront]: useWishlist.load params->', params);

      if (apiState.getCustomerToken()) {
        if(!params?.searchParams) {
          params = {
            searchParams: {
              currentPage: 1,
              pageSize: 100
            }
          }
        }

        const {data} = await app.$vsf.$magento.api.wishlist(params?.searchParams, params?.customQuery || {wishlist: 'wishlist'}, params?.customHeaders);

        Logger.debug('[Result]:', {data});
        const loadedWishlist = data?.customer?.wishlists || [];
        if (loadedWishlist[0]) {
          // @ts-ignore
          [wishlistStore.wishlist] = loadedWishlist;
        }
      }

      error.value.load = null;
    } catch (err) {
      error.value.load = err;
      Logger.error('useWishlist/load', err);
    } finally {
      state.loading = false;
    }

    return wishlistStore.wishlist;
  };

  const isInWishlist = ({product}: UseWishlistIsInWishlistParams) => {
    Logger.debug('useWishlist/isInWishlist', product);

    const wishlistProduct = findItemOnWishlist(wishlistStore.wishlist, product);

    return !!(wishlistProduct?.id && wishlistProduct?.quantity);
  };

  const setWishlist = (newWishlist: Wishlist) => {
    wishlistStore.wishlist = newWishlist;
    Logger.debug('useWishlist/setWishlist', newWishlist);
  };

  const removeItem = async ({product, customQuery = {removeProductsFromWishlist: 'removeProductsFromWishlist'}, customHeaders}: UseWishlistRemoveItemParams) => {
    trackRemoveFromWishlist([{product: product}])
    if (!apiState.getCustomerToken()) return await guestRemoveItem({product});
    Logger.debug('useWishlist/removeItem', product);

    try {
      state.loading = true;
      state.itemInProgress = product;

      Logger.debug('[Magento Storefront]: useWishlist.removeItem params->', {
        currentWishlist: wishlistStore.wishlist,
        product,
        customQuery,
        customHeaders,
      });

      const itemOnWishlist = findItemOnWishlist(wishlistStore.wishlist, product);
      const {data} = await app.context.$vsf.$magento.api.removeProductsFromWishlist({
        id: '0',
        items: [itemOnWishlist.id],
      }, {removeProductsFromWishlist: 'removeProductsFromWishlist'}, customHeaders);

      Logger.debug('[Result]:', {data});
      error.value.removeItem = null;
      wishlistStore.$patch((state) => {
        state.wishlist = data?.removeProductsFromWishlist?.wishlist || {};
      });
    } catch (err) {
      error.value.removeItem = err;
      Logger.error('useWishlist/removeItem', err);
    } finally {
      state.loading = false;
    }
  };

  const updateItem = async ({product, productConfiguration, customQuery, customHeaders}) => {
    const updateData = async (items) => {
      let wishlist = {};

      if (!apiState.getCustomerToken())  {
        const {data} = await app.context.$vsf.$magento.api.guestUpdateProductsInWishlist({id: wishlistStore.guestId, items:items}, {});
        wishlist = data['guestUpdateProductsInWishlist']?.wishlist || {};
      }else {
        const {data} = await app.context.$vsf.$magento.api.updateProductsInWishlist({id: '0', items: items, token: apiState.getCustomerToken()}, {});
        wishlist = data['updateProductsInWishlist']?.wishlist || {};
      }

      if(Object.keys(wishlist).length) {
        wishlistStore.$patch((state) => {
          state.wishlist = wishlist;
        });
      }
    }

    Logger.debug('useWishlist/updateItem', product);

    try {
      state.loading = true;
      state.itemInProgress = product;
      Logger.debug('[Magento Storefront]: useWishlist.updateItem params->', {
        currentWishlist: wishlistStore.wishlist,
        product,
        productConfiguration,
        customQuery,
        customHeaders,
      });

      const itemOnWishlist = findItemOnWishlist(wishlistStore.wishlist, product);

      const wishlistItems = [];
      switch (product.__typename) {
        case 'ConfigurableProduct': {
          const selectedOptions = Object.values(productConfiguration as object);

          wishlistItems.push({
            wishlist_item_id: itemOnWishlist.id,
            quantity: 1,
            selected_options: selectedOptions,
          })

          break;
        }
        case 'BundleProduct': {
          const createEnteredOptions = () =>
            // eslint-disable-next-line implicit-arrow-linebreak
            product.bundle_options.map((bundleOption) => ({
              ...bundleOption,
              value: bundleOption.value.toString(),
            }));

          wishlistItems.push({
            wishlist_item_id: itemOnWishlist.id,
            quantity: 1,
            entered_options: createEnteredOptions(),
          })

          break;
        }
        case 'GroupedProduct': {
          wishlistItems.push(product.items.map((item) => ({
            wishlist_item_id: itemOnWishlist.id,
            quantity: 1
          })))

          break
        }
        default: {
          wishlistItems.push({
            wishlist_item_id: itemOnWishlist.id,
            quantity: 1
          })

          break;
        }
      }

      await updateData(wishlistItems);

    } catch (err) {
      error.value = err;
      Logger.error('useWishlist/updateItem', err);
    } finally {
      state.loading = false;
    }
  }

  const removeItems = async ({products, customQuery, customHeaders}) => {
    if (!apiState.getCustomerToken()) return await guestRemoveItems({products});
    Logger.debug('useWishlist/removeItem', 'mass');

    try {
      state.loading = true;
      Logger.debug('[Magento Storefront]: useWishlist.removeItem params->', {
        currentWishlist: wishlistStore.wishlist,
        customQuery,
        customHeaders,
      });

      const itemsInWishlist = [];

      for(let p = 0; p < products.length; p++) {
        const product = products[p].product;
        itemsInWishlist.push(findItemOnWishlist(wishlistStore.wishlist, product)?.id);
      }

      const {data} = await app.context.$vsf.$magento.api.removeProductsFromWishlist({
        id: '0',
        items: itemsInWishlist,
      }, {removeProductsFromWishlist: 'removeProductsFromWishlist'}, customHeaders);

      Logger.debug('[Result]:', {data});
      error.value.removeItem = null;
      wishlistStore.$patch((state) => {
        state.wishlist = data?.removeProductsFromWishlist?.wishlist || {};
      });
    } catch (err) {
      error.value.removeItem = err;
      Logger.error('useWishlist/removeItem', err);
    } finally {
      state.loading = false;
    }
  };

  const loadItemsCount = async (): Promise<number | null> => {
    if (!apiState.getCustomerToken()) return  await loadGuestItemsCount();

    Logger.debug('useWishlist/wishlistItemsCount');
    let itemsCount: number | null = null;

    try {
      state.loading = true;
      error.value.loadItemsCount = null;
      if (apiState.getCustomerToken()) {
        const {data} = await app.context.$vsf.$magento.api.wishlistItemsCount();

        Logger.debug('[Result]:', {data});
        const loadedWishlist: Wishlist[] = data?.customer?.wishlists || [];
        itemsCount = calculateWishlistTotal(loadedWishlist);
        wishlistStore.$patch((state) => {
          state.wishlist.items_count = itemsCount;
        });
      }
    } catch (err) {
      error.value.loadItemsCount = err;
      Logger.error('useWishlist/wishlistItemsCount', err);
    } finally {
      state.loading = false;
    }

    return itemsCount;
  };

  // eslint-disable-next-line consistent-return
  const addItem = async ({product, productConfiguration, customQuery = {addProductToWishList: 'addProductToWishList'}, customHeaders}) => {
    trackAddToWishlist([{product: product}]);
    if (!apiState.getCustomerToken()) return await guestAddItem({product, productConfiguration});
    Logger.debug('useWishlist/addItem', product);

    try {
      state.loading = true;
      state.itemInProgress = product;
      Logger.debug('[Magento Storefront]: useWishlist.addItem params->', {
        currentWishlist: wishlistStore.wishlist,
        product,
        customQuery,
        customHeaders,
      });

      if (!wishlistStore.wishlist) {
        await load({customQuery: {wishlist: 'wishlist'}});
      }

      const itemOnWishlist = findItemOnWishlist(wishlistStore.wishlist, product);

      if (itemOnWishlist) {
        return;
      }

      if (!app.$vsf.$magento.config.state.getCustomerToken()) {
        Logger.error('Need to be authenticated to add a product to wishlist');
      }

      // @ts-ignore
      // eslint-disable-next-line no-underscore-dangle
      switch (product.__typename) {
        case 'VirtualProduct':
        case 'DownloadableProduct':
        case 'GroupedProduct':
        case 'GiftCard':
        case 'SimpleProduct': {
          const {data} = await app.context.$vsf.$magento.api.addProductToWishList({
            id: '0',
            items: [{
              sku: product.sku,
              quantity: 1,
            }],
          }, customQuery, customHeaders);

          Logger.debug('[Result]:', {data});

          wishlistStore.$patch((state) => {
            state.wishlist = data?.addProductsToWishlist?.wishlist || {};
          });

          break;
        }
        case 'ConfigurableProduct': {
          const selectedOptions = Object.values(productConfiguration as object);
          const {data: configurableProductData} = await app.context.$vsf.$magento.api.addProductToWishList({
            id: '0',
            items: [{
              sku: product.configurable_product_options_selection?.variant?.sku || product.sku,
              quantity: 1,
              parent_sku: product.sku,
              selected_options: selectedOptions
            }],
          }, customQuery, customHeaders);

          Logger.debug('[Result]:', {data: configurableProductData});

          wishlistStore.$patch((state) => {
            state.wishlist = configurableProductData?.addProductsToWishlist?.wishlist || {};
          });

          break;
        }
        case 'BundleProduct': {
          const {data: bundleProductData} = await app.context.$vsf.$magento.api.addProductToWishList({
            id: '0',
            items: [{
              sku: product.sku,
              quantity: 1,
              entered_options: [],
            }],
          }, customQuery, customHeaders);

          Logger.debug('[Result]:', {data: bundleProductData});

          wishlistStore.$patch((state) => {
            state.wishlist = bundleProductData?.addProductsToWishlist?.wishlist || {};
          });

          break;
        }
        default:
          // @ts-ignore
          // eslint-disable-next-line no-underscore-dangle
          Logger.error(`Product Type ${product.__typename} not supported in add to wishlist yet`);
      }
    } catch (err) {
      error.value.addItem = err;
      Logger.error('useWishlist/addItem', err);
    } finally {
      state.loading = false;
    }
  };

  // eslint-disable-next-line @typescript-eslint/require-await
  const clear = async () => {
    Logger.debug('useWishlist/clear');

    try {
      state.loading = true;
      error.value.clear = null;
      wishlistStore.$patch((state) => {
        state.wishlist = {};
      });
    } catch (err) {
      error.value.clear = err;
      Logger.error('useWishlist/clear', err);
    } finally {
      state.loading = false;
    }
  };

  const afterAddingWishlistItemToCart = (
    {product, cartError}: UseWishlistAfterAddingWishlistItemToCartParams,
  ) => {
    Logger.debug('useWishlist/afterAddingItemToCart', product);

    if (!isInWishlist({product})) return;

    try {
      if (cartError?.message) {
        sendNotification({
          id: Symbol('product_added_to_cart_from_wishlist_error'),
          message: cartError.message,
          type: 'danger',
          icon: 'cross',
          persist: false,
          title: 'Wishlist error',
        });
      } else {
        // eslint-disable-next-line promise/catch-or-return
        removeItem({product})
          // eslint-disable-next-line promise/always-return
          .then(() => {
            sendNotification({
              id: Symbol('product_added_to_cart_from_wishlist'),
              message: app.i18n.t(
                'You added {product} to your shopping cart.',
                {product: product.name},
              ) as string,
              type: 'success',
              icon: 'check',
              persist: false,
              title: 'Wishlist',
            });
          });
      }
    } catch (err) {
      error.value.afterAddingWishlistItemToCart = err;
      Logger.error('useWishlist/afterAddingWishlistItemToCart', err);
    }
  };

  const addOrRemoveItem = async ({product, productConfiguration, customQuery, customHeaders}) => {
    await (isInWishlist({product}) ? removeItem({product, customQuery, customHeaders}) : addItem({product, productConfiguration, customQuery, customHeaders}));
  };

  return {
    loadItemsCount,
    isInWishlist,
    addItem,
    load,
    removeItem,
    removeItems,
    updateItem,
    clear,
    setWishlist,
    afterAddingWishlistItemToCart,
    addOrRemoveItem,
    loading: (!apiState.getCustomerToken()) ? readonly(guestLoading) : readonly(loading),
    error: readonly(error),
    itemInProgress: (!apiState.getCustomerToken()) ? guestItemInProgress : itemInProgress
  };
}

export default useWishlist;
export * from './useWishlist';
