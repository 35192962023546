



import {defineComponent, useContext} from '@nuxtjs/composition-api';
import type {PropType} from '@nuxtjs/composition-api';
import {SfButton} from '@storefront-ui/vue';
import type {NuxtError} from '@nuxt/types';
import StaticBlock from "~/components/Palmers/BaseComponents/Cms/StaticBlock.vue";
import type {MetaInfo} from "vue-meta";
import Notification from '~/components/Palmers/BaseComponents/Notification/Notification.vue';
import GTM from '~/components/Palmers/GTM.vue';
export default defineComponent({
  components: {
    SfButton,
    StaticBlock,
    Notification,
    GTM
  },
  props: {
    error: {
      type: Object as PropType<NuxtError>,
      required: true,
    },
  },
  setup() {
    const context = useContext();

    const redirectUser = (path, code) => {
      context.redirect(code, path);
    }

    return {
      redirectUser
    }
  },
  mounted() {
    this.$nextTick(() => {
      if(!(this.$route.fullPath.indexOf('no-route') > -1)) {
        //@ts-ignore
        this.redirectUser('/no-route', 404);
      }
    })
  },
  head() {
    return {
      title: this.$t('404 Page not found')
    } as MetaInfo;
  }
});
