import { Logger } from '~/helpers/logger';
import { VsfContext } from '~/composables/context';
import { ComposableFunctionArgs } from '~/composables/types';
import type { GetProductSearchParams } from '~/modules/catalog/product/types';

export const searchReviewsOutOfStockCommand = {
  execute: async (context: VsfContext, params?: ComposableFunctionArgs<GetProductSearchParams>) => {
    Logger.debug('[Magento] search review params input:', JSON.stringify(params, null, 2));

    const {
      customQuery,
      ...input
    } = params;

    //@ts-ignore
    const { data } = await context.$magento.api.productReviewOutOfStock(
      //@ts-ignore
      input as GetProductSearchParams,
      params?.customQuery || null,
      params?.customHeaders,
    );

    Logger.debug('[Result]:', { data });

    return data?.route || [];
  },
};
