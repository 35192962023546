import type {
  ConfigurableProduct,
  Maybe,
  SimpleProduct
} from '~/modules/GraphQL/types';
import {ConfigurableProductOptions, MediaGalleryInterface, ProductImage} from "~/modules/GraphQL/types";

import productGetters from "~/modules/catalog/product/getters/productGetters";
import {PalmersProductConfigurableVariantsEntry, PalmersProductInterface} from "~/modules/palmers/GraphQl/types";

export interface PalmersProductGetters {
  getLabels: (product: PalmersProductInterface, mode: string) => any[];
  isConfigurable: (product: PalmersProductInterface) => boolean;
  getVariant: (products: { items: [ConfigurableProduct] }) => SimpleProduct;
  getVariantIndexByConfiguration: (product: PalmersProductInterface, productConfiguration: any[]) => number;
  getVariantByConfiguration: (product: PalmersProductInterface, productConfiguration: any[]) => SimpleProduct;
  getConfigurationByVariant: (product: PalmersProductInterface) => any[];
  getImageHover: (product: PalmersProductInterface, index?: Number) => string;
  getDiscountPercent: (product: PalmersProductInterface) => number;
  getLabelDueDate: (product: PalmersProductInterface) => object;
  getCoverImage: (product: PalmersProductInterface, index?: Number) => ProductImage;
  getVariantIndexByUid: (product: PalmersProductInterface, uid: String) => Number;
  getMediaGallery: (product: PalmersProductInterface, index?: Number) => Maybe<Array<Maybe<MediaGalleryInterface>>>;
  getPreselectedConfiguration: (product: PalmersProductInterface) => any[];
  getPreselectedConfigurationByAttributes: (product: PalmersProductInterface, productConfiguration: any[]) => any[];
  filterConfigurableOptionsByExisting: (product: PalmersProductInterface, productConfiguration: any[]) => Maybe<Array<Maybe<ConfigurableProductOptions>>>;
  isExistingAttributeOption: (product: PalmersProductInterface, attribute: ConfigurableProductOptions, optionUid: string, productConfiguration: any[]) => boolean;
  filterConfigurableOptionsByExistingColor: (product: PalmersProductInterface, productConfiguration: any[]) => Maybe<Array<Maybe<ConfigurableProductOptions>>>;

  [getterName: string]: any;
}

const ATTRIBUTE_SIZE = ['pa_size', 'muenze_schleife', 'size', 'banderole'];
const ATTRIBUTE_COLOR = ['color', 'muenze_boxfarbe', 'pa_color', 'boxfarbe']

export const getLabels = (product: PalmersProductInterface, mode: string = 'list'): any[] => {
  return product?.['am_label_data']?.[mode]?.['items'] || [];
};

export const getVariant = (products: { items: [ConfigurableProduct] }): SimpleProduct => {
  return products.items[0].configurable_product_options_selection.variant;
};

export const getVariantIndexByUid = (product: PalmersProductInterface, uid): Number => {
  return product.variants.findIndex((variant) => variant.product.uid === uid);
};

export const getMediaGallery = (product: PalmersProductInterface, index = 0): Maybe<Array<Maybe<MediaGalleryInterface>>> => {
  const productType = productGetters.getTypeId(product);
  let mediaGallery = product.media_gallery;

  const sortByPosition = (gallery) => {
    return gallery.sort((a, b) => a.position - b.position);
  }

  if (productType === 'ConfigurableProduct' && product?.variants?.[index]?.product?.media_gallery) {
    mediaGallery = [...sortByPosition(product.variants[index].product.media_gallery), ...sortByPosition(mediaGallery)];
  }

  const uniqueUrls = new Set();
  const mediaGalleryFiltered = mediaGallery.filter(mediaItem => {
    const isDuplicate = uniqueUrls.has(mediaItem.url);
    return !isDuplicate && !mediaItem.disabled;
  });

  return mediaGalleryFiltered.length ? mediaGalleryFiltered : mediaGallery.filter((mediaItem) => !mediaItem.disabled);
};

export const getImageHover = (product: PalmersProductInterface, index = 0): string => {
  const productType = productGetters.getTypeId(product);
  let imageHover = '';

  if (productType === 'SimpleProduct') {
    imageHover = product?.mouseover_image?.url;
  } else {
    imageHover = product?.variants?.[index]?.product?.mouseover_image?.url || product?.mouseover_image?.url || '';
  }

  return imageHover === 'no_selection' ? '' : imageHover;
};

export const getDiscountPercent = (product: PalmersProductInterface): number => {
  const price = productGetters.getPrice(product);

  if (price.special === null || price.regular === price.special) {
    return null;
  }

  return Math.round((price.regular - price.special) / price.regular * 100);
};

export const getLabelDueDate = (product: PalmersProductInterface): object => {
  const discountPercent = getDiscountPercent(product);

  if (!discountPercent) return null;

  if (!product.hasOwnProperty('special_from_date') || !product.special_from_date) return null;

  if (!product.hasOwnProperty('special_to_date') || !product.special_to_date) return null;

  const days = new Date(product.special_to_date).getDay() - new Date(product.special_from_date).getDay();

  if (days === 0) return null;

  let text = 'Day-Best-price*:';

  if (days > 1) {
    text = 'Days-Best-price*:';
  }

  return {
    regularPrice: productGetters.getPrice(product).regular,
    discount: `-${discountPercent}%`,
    text: text,
    period: days
  }
};

export const getCoverImage = (product: PalmersProductInterface, index = 0): ProductImage => {
  let image = product?.image;

  if (isConfigurable(product)) {
    image = product?.variants?.[index]?.product?.image;

    if (!image?.url || (image?.url.toString() as string).toLowerCase().indexOf('placeholder') > -1) {
      image = product?.image;
    }
  }

  return image || {
    url: '',
    label: ''
  };
};

const getPreselectedConfiguration = (product: PalmersProductInterface): any[] => {
  const productConfiguration = [];

  if (product?.configurable_options?.length) {
    for (let a = 0; a < product.configurable_options.length; a++) {
      const attribute = product.configurable_options[a];

      if (!product?.variants?.[0]?.product) continue;

      productConfiguration[attribute.attribute_uid] = getActiveOptionUidByAttribute(attribute, product.variants[0].product);
    }
  }

  return productConfiguration;
}

const isConfigurable = (product: PalmersProductInterface): boolean => {
  const productType = productGetters.getTypeId(product);

  return (productType === 'ConfigurableProduct');
}

const getVariantIndexByConfiguration = (product: PalmersProductInterface, productConfiguration: any[]): number => {
  let variantIndex = 0;

  const attributeColor = getAttributeColor(product);
  const attributeSize = getAttributeSize(product);

  if (attributeColor.length && attributeSize.length) {
    const activeColorId = attributeColor[0].values.filter((option) => option.uid === productConfiguration[attributeColor[0].attribute_uid])[0]?.value_index;
    const sizeActiveOptionId = attributeSize[0].values.filter((option) => option.uid === productConfiguration[attributeSize[0].attribute_uid])[0]?.value_index;
    variantIndex = product.variants.findIndex((variant) => variant.product[attributeColor[0].attribute_code] === activeColorId && variant.product[attributeSize[0].attribute_code] === sizeActiveOptionId);
  }

  if (attributeColor.length && (!variantIndex || variantIndex <= -1)) {
    const activeColorId = attributeColor[0].values.filter((option) => option.uid === productConfiguration[attributeColor[0].attribute_uid])[0]?.value_index;
    variantIndex = product.variants.findIndex((variant) => variant.product[attributeColor[0].attribute_code] === activeColorId);
  }

  if (attributeSize.length && (!variantIndex || variantIndex <= -1)) {
    const sizeActiveOptionId = attributeSize[0].values.filter((option) => option.uid === productConfiguration[attributeSize[0].attribute_uid])[0]?.value_index;
    variantIndex = product.variants.findIndex((variant) => variant.product[attributeSize[0].attribute_code] === sizeActiveOptionId);
  }

  return (variantIndex > -1) ? variantIndex : 0;
}

const getVariantByConfiguration = (product: PalmersProductInterface, productConfiguration: any[]): SimpleProduct => {
  let variantIndex = getVariantIndexByConfiguration(product, productConfiguration);
  return product?.variants?.[variantIndex]?.product as SimpleProduct;
}

const getConfigurationByVariant = (product: PalmersProductInterface): any[] => {
  const productConfiguration = [];
  if (!product?.configurable_product_options_selection?.variant) return productConfiguration;
  const variant = product.configurable_product_options_selection.variant;
  const variantProduct = getVariantProductBySku(product, variant.sku);
  const attributeColor = getAttributeColor(product);
  const attributeSize = getAttributeSize(product);

  if (attributeColor.length) {
    productConfiguration[attributeColor[0].attribute_uid] = getActiveOptionUidByAttribute(attributeColor[0], variantProduct);
  }

  if (attributeSize.length) {
    productConfiguration[attributeSize[0].attribute_uid] = getActiveOptionUidByAttribute(attributeSize[0], variantProduct);
  }

  return productConfiguration;
}

const filterConfigurableOptionsByExisting = (product: PalmersProductInterface, productConfiguration: any[]): Maybe<Array<Maybe<ConfigurableProductOptions>>> => {
  if (!product?.configurable_options || !product.variants.length) return [];

  let attributeColor,
    attributeSize,
    allPerColorSizes = [],
    allPerSizeColors = [];

  attributeColor = getAttributeColor(product);
  attributeSize = getAttributeSize(product)

  if (attributeColor.length) {
    for (let s = 0; s < product.variants.length; s++) {
      allPerSizeColors.push(product.variants[s].product[attributeColor[0].attribute_code]);
    }

    attributeColor[0].values = attributeColor[0].values.filter((option) => allPerSizeColors.includes(option.value_index));
  } else if (attributeSize.length) {
    for (let c = 0; c < product.variants.length; c++) {
      allPerColorSizes.push(product.variants[c].product[attributeSize[0].attribute_code]);
    }

    attributeSize[0].values = attributeSize[0].values.filter((option) => allPerColorSizes.includes(option.value_index));
  }

  return [
    ...attributeColor,
    ...attributeSize
  ];
}

const getAttributeColor = (product: PalmersProductInterface): Maybe<Array<Maybe<ConfigurableProductOptions>>> => {
  if (!product?.configurable_options) return [];
  const configurableOptions = JSON.parse(JSON.stringify(product.configurable_options));
  return configurableOptions.filter((attribute) => ATTRIBUTE_COLOR.includes(attribute.attribute_code));
}

const getAttributeSize = (product: PalmersProductInterface): Maybe<Array<Maybe<ConfigurableProductOptions>>> => {
  if (!product?.configurable_options) return [];
  const configurableOptions = JSON.parse(JSON.stringify(product.configurable_options));
  return configurableOptions.filter((attribute) => ATTRIBUTE_SIZE.includes(attribute.attribute_code));
}

const getVariantProductBySku = (product: PalmersProductInterface, sku: string): PalmersProductInterface => {
  return product.variants.find((variantProduct) => variantProduct.product.sku === sku).product
}

const getActiveOptionUidByAttribute = (attribute: Maybe<ConfigurableProductOptions>, variant: PalmersProductInterface): string => {
  return attribute.values.find((option) => option.value_index == variant[attribute.attribute_code]).uid
}

const getActiveOptionIdByUid = (attribute: Maybe<ConfigurableProductOptions>, uid: string): number => {
  return attribute.values.find((option) => option.uid == uid)?.value_index
}

const getAllVariantsWithOptionId = (configurableProduct: PalmersProductInterface, attribute_code: string, id: number): PalmersProductConfigurableVariantsEntry[] => {
  return configurableProduct.variants.filter((variant) => variant.product[attribute_code] === id);
}

const isExistingAttributeOption = (product: PalmersProductInterface, attribute: ConfigurableProductOptions, optionUid: string, productConfiguration: any[]): boolean => {
  const attributeOption = attribute.values.find((option) => option.uid === optionUid);
  const reverseAttributeUid = Object.keys(productConfiguration).find((attributeUid) => attributeUid !== attribute.attribute_uid);
  const reverseAttribute = product.configurable_options.find((attribute) => attribute.attribute_uid === reverseAttributeUid);
  if(reverseAttribute) {
    const reverseAttributeActiveOptionId = reverseAttribute.values.find((option) => option.uid === productConfiguration[reverseAttribute.attribute_uid])?.value_index;

    return Boolean(product?.variants?.find((variant) => variant.product[attribute.attribute_code] === attributeOption.value_index && variant.product[reverseAttribute.attribute_code] === reverseAttributeActiveOptionId));
  }

  return Boolean(product?.variants?.find((variant) => variant.product[attribute.attribute_code] === attributeOption.value_index));
}

const filterConfigurableOptionsByExistingColor = (product: PalmersProductInterface, productConfiguration: any[]): Maybe<Array<Maybe<ConfigurableProductOptions>>> => {
  if (!product?.configurable_options) return [];

  let configurableOptions = JSON.parse(JSON.stringify(product.configurable_options));

  let attributeColor,
    allPerColor = [];

  attributeColor = getAttributeColor(product);

  if (attributeColor.length) {
    for (let s = 0; s < product.variants.length; s++) {
      allPerColor.push(product.variants[s].product.color);
    }

    attributeColor[0].values = attributeColor[0].values.filter((option) => allPerColor.includes(option.value_index));

    configurableOptions = [
      attributeColor[0]
    ];
  }

  return configurableOptions;
}

const getPreselectedConfigurationByAttributes = (product: PalmersProductInterface, productConfiguration: any[]): any[] => {
  const productConfigurationNew = [];

  if (!product?.configurable_options) return productConfigurationNew;

  let attributeColor,
    colorActiveOptionId,
    attributeSize,
    allPerColor,
    allPerColorSizes = [];

  attributeColor = getAttributeColor(product);
  attributeSize = getAttributeSize(product);


  if (attributeColor.length) {
    colorActiveOptionId = getActiveOptionIdByUid(attributeColor[0], productConfiguration[attributeColor[0].attribute_uid]);

    productConfigurationNew[attributeColor[0].attribute_uid] = productConfiguration[attributeColor[0].attribute_uid];

    if (attributeSize.length) {
      allPerColor = product.variants.filter((variant) => variant.product[attributeColor[0].attribute_code] === colorActiveOptionId);

      for (let c = 0; c < allPerColor.length; c++) {
        allPerColorSizes.push(allPerColor[c].product[attributeSize[0].attribute_code]);
      }

      attributeSize[0].values = attributeSize[0].values.filter((option) => allPerColorSizes.includes(option.value_index));

      if(attributeSize[0].values[0]?.uid) {
        productConfigurationNew[attributeSize[0].attribute_uid] = attributeSize[0].values[0].uid;
      }
    }
  } else if(attributeSize.length) {

    productConfigurationNew[attributeSize[0].attribute_uid] = productConfiguration[attributeSize[0].attribute_uid];
  }

  return productConfigurationNew;
}

const palmersProductGetters: PalmersProductGetters = {
  getLabels,
  getVariant,
  getVariantIndexByConfiguration,
  getImageHover,
  getDiscountPercent,
  getLabelDueDate,
  getCoverImage,
  getVariantIndexByUid,
  getVariantByConfiguration,
  getMediaGallery,
  getPreselectedConfiguration,
  filterConfigurableOptionsByExisting,
  getConfigurationByVariant,
  isConfigurable,
  isExistingAttributeOption,
  filterConfigurableOptionsByExistingColor,
  getPreselectedConfigurationByAttributes
};

export default palmersProductGetters;

