export default (context) => {
  const {$vsf} = context;

  //@ts-ignore
  const sessionId = $vsf.$magento.config.state.getSessionId();

  if (!sessionId) {
    const uid = function(){
      return Date.now().toString(36) + Math.random().toString(36).substr(2);
    }

    //@ts-ignore
    $vsf.$magento.config.state.setSessionId(uid());
  }
}
