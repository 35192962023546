import {computed, reactive, readonly, Ref, ref, useContext} from '@nuxtjs/composition-api';
import {
  ComposableFunctionArgs,
} from '~/composables/types';
import { Logger } from '~/helpers/logger';
import { getGuestShippingMethodsCommand } from '~/modules/checkout/composables/useGetShippingMethods/commands/getGuestShippingMethodsCommand';
import { getCustomerShippingMethodsCommand } from '~/modules/checkout/composables/useGetShippingMethods/commands/getCustomerShippingMethodsCommand';
import { UseGetShippingMethodsInterface } from '~/modules/checkout/composables/useGetShippingMethods/useGetShippingMethods';
import { AvailableShippingMethod } from '~/modules/GraphQL/types';

/**
 * Allows fetching shipping methods available for a given cart.
 *
 * See the {@link UseGetShippingMethodsInterface} for a list of methods and values available in this composable.
 */
const state = reactive({
  loading: false
});
export function useGetShippingMethods(): UseGetShippingMethodsInterface<AvailableShippingMethod> {
  const loading: Ref<boolean> = computed(() => state.loading);
  const error = ref({ load: null });
  const {$vsf} = useContext();

  const load = async (params: ComposableFunctionArgs<{ cartId: string }>): Promise<any[]> => {
    Logger.debug('useGetShippingMethods/load', { params });
    let result = [];
    try {
      state.loading = true;

      result = await getGuestShippingMethodsCommand.execute($vsf, params);

      Logger.debug('[Result]:', { result });

      error.value.load = null;
    } catch (err) {
      error.value.load = err;
      Logger.error('useGetShippingMethods/load', err);
    } finally {
      state.loading = false;
    }

    return result;
  };

  return {
    load,
    loading: readonly(loading),
    error: readonly(error),
  };
}

export * from './useGetShippingMethods';
