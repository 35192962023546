import {Cart, ProductInterface} from '~/modules/GraphQL/types';
import {productMatch} from '~/modules/wishlist/helpers/productMatch';

export const findItemOnCart = (currentCart: Cart, product: ProductInterface) => {
  const cart: Cart = Array.isArray(currentCart) ? currentCart[0] : currentCart;

  return cart
    ?.items?.find((item) => productMatch(item.product, product));
};

export const findItemsOnCart = (currentCart: Cart, product: ProductInterface) => {
  const cart: Cart = Array.isArray(currentCart) ? currentCart[0] : currentCart;

  return cart
    ?.items?.filter((item) => productMatch(item.product, product));
};
