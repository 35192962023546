import {Middleware} from '@nuxt/types';

const middleware: Middleware = (context) => {
  if (!context.app.$vsf.$magento.config.state.getCustomerToken() && context.route.name.indexOf('customer') > -1) {
    const login = context.localeRoute({name: 'login'});
    if (!(context.route.name.indexOf('login') > -1)) {
      context.redirect(login);
    }
  } else if (
    context.app.$vsf.$magento.config.state.getCustomerToken() && context.route.name.indexOf('login') > -1
    || context.app.$vsf.$magento.config.state.getCustomerToken() && context.route.name.indexOf('register') > -1
  ) {
    const myAccount = context.localeRoute({name: 'customer-overview'});
    if (!(context.route.name.indexOf('customer-overview') > -1)) {
      context.redirect(myAccount);
    }
  }
};
export default middleware;
