import {computed, reactive, readonly, ref, useContext, watch} from '@nuxtjs/composition-api';
import {Logger} from '~/helpers/logger';
import {setPaymentMethodOnCartCommand} from '~/modules/checkout/composables/usePaymentProvider/commands/setPaymentMethodOnCartCommand';
import {getAvailablePaymentMethodsCommand} from '~/modules/checkout/composables/usePaymentProvider/commands/getAvailablePaymentMethodsCommand';
import useCart from '~/modules/checkout/composables/useCart';
import type {PaymentMethodParams, UsePaymentProviderErrors, UsePaymentProviderInterface, UsePaymentProviderSaveParams,} from './usePaymentProvider';
import {CustomHeaders, CustomQuery} from '~/types/core';
import {useNotifications, usePayments} from "~/composables/Palmers";

/**
 * Allows loading the available payment
 * methods for current cart, and selecting (saving) one of them.
 *
 * See the {@link UsePaymentProviderInterface} for a list of methods and values available in this composable.
 */
const state = reactive({
  loading: false
});
export function usePaymentProvider(): UsePaymentProviderInterface {
  const context = useContext();
  const {cart, load: loadCart} = useCart();
  const {setKlarna, klarna, getKlarnaOrderData, klarnaRefresh} = usePayments();
  const {getAllErrors, sendMessage} = useNotifications();
  const loading = computed(() => state.loading);
  const error = ref<UsePaymentProviderErrors>({
    load: null,
    save: null,
  });

  const save = async (params: UsePaymentProviderSaveParams) => {
    Logger.debug('usePaymentProvider.save');
    let result = null;

    try {
      state.loading = true;
      const paymentMethodParams: PaymentMethodParams = {
        cart_id: cart.value.id,
        payment_method: {
          ...params.paymentMethod,
        },
        customQuery: params.customQuery,
        customHeaders: params?.customHeaders,
      };

      // @ts-ignore
      cart.value.selected_payment_method = params.paymentMethod;

      if (params.paymentMethod.code.indexOf('klarna_') > -1) {
        if (!klarna) {
          throw new Error('Klarna is not defined')
        }


        // @ts-ignore
        const {authorization_token, approved} = await new Promise((resolve, reject) => {
          const data = getKlarnaOrderData(cart.value);

          window['Klarna'].Payments.authorize({
            payment_method_category: klarna.value['payment_method_categories'][0].identifier,
          }, data, function (res) {
            if (!res) reject('Authorization failed');
            resolve(res);
          })
        })


        if (!approved || !authorization_token) {
          throw new Error('Auth is not success')
        }

        paymentMethodParams.payment_method['klarna'] = {
          authorization_token: authorization_token
        };

        //@ts-ignore
        const {data} = await context.app.$vsf.$magento.api.setPaymentMethodAndPlaceOrder({
          cart_id: paymentMethodParams.cart_id,
          payment_method: paymentMethodParams.payment_method
        })


        result = data?.['setPaymentMethodAndPlaceOrder']?.order;

        error.value.save = null;
      }else {
        result = await setPaymentMethodOnCartCommand.execute(context, paymentMethodParams);
        error.value.save = null;
      }
    } catch (err) {
      // @ts-ignore
      error.value.save = getAllErrors(err);
      sendMessage('Cannot save payment method', error.value.save)
      Logger.error('usePaymentProvider/save', err);
    } finally {
      state.loading = false;
    }

    Logger.debug('[Result]:', {result});
    return result;
  };

  watch(klarnaRefresh, async () => {
    await createKlarnaPaymentSession();
  })

  const createKlarnaPaymentSession = async () => {
    //@ts-ignore
    const klarnaData = await context.$vsf.$magento.api.createKlarnaPaymentsSession(cart.value?.id);

    const {data} = klarnaData;

    setKlarna(data?.['createKlarnaPaymentsSession']);

    if (data?.['createKlarnaPaymentsSession']?.client_token) {
      window['Klarna'].Payments.init({
        client_token: data['createKlarnaPaymentsSession'].client_token
      })
    }
  }

  const load = async (customQuery: CustomQuery = {getAvailablePaymentMethods: 'getAvailablePaymentMethods'}, customHeaders: CustomHeaders = {}) => {
    Logger.debug('usePaymentProvider.load');
    let result = null;

    if (!cart.value?.id) {
      await loadCart();
    }

    if(cart.value?.available_payment_methods?.length) return;

    try {
      state.loading = true;

      await createKlarnaPaymentSession();

      result = await getAvailablePaymentMethodsCommand.execute(context, cart.value.id, customQuery, customHeaders);

      error.value.load = null;
    } catch (err) {
      error.value.load = err;
      Logger.error('usePaymentProvider/load', err);
    } finally {
      state.loading = false;
    }

    Logger.debug('[Result]:', {result});
    return result;
  };

  return {
    load,
    save,
    error: readonly(error),
    loading: readonly(loading),
  };
}

export * from './usePaymentProvider';
export default usePaymentProvider;
