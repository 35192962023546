import {PiniaPluginContext} from 'pinia';
import {Plugin} from '@nuxt/types';
import {ref, set} from '@nuxtjs/composition-api';
import type {StoreConfig} from '~/modules/GraphQL/types';

const storeConfigPlugin: Plugin = async ({$pinia, app}) => {
  if(!$pinia?.state?.value?.magentoConfig?.storeConfig || $pinia?.state?.value?.magentoConfig?.storeConfig?.store_code !== app.$vsf.$magento.config.state.getStore()) {
    //@ts-ignore
    const {data}: { data: { storeConfig?: StoreConfig } } = await app.$vsf.$magento.api.getStoreConfig();

    $pinia.use(({store}: PiniaPluginContext) => {
      if (store.$id !== 'magentoConfig') return;
      const storeConfig = ref(data?.storeConfig || {});

      // eslint-disable-next-line no-prototype-builtins
      if (!store.$state.hasOwnProperty('storeConfig')) {
        set(store.$state, 'storeConfig', storeConfig);
      } else {
        // eslint-disable-next-line no-param-reassign
        store.$state.storeConfig = storeConfig;
      }
    });
  }
};

export default storeConfigPlugin;
